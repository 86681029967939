
import { Component, Vue, Watch } from 'vue-property-decorator';
import { readFilms, readPerformances, readScreens, readSiteCode } from '@/store/main/getters';
import {
  dispatchGetPerformances, dispatchGetFilms, dispatchGetScreens, dispatchUpdatePerformance,
  dispatchCreatePerformance, dispatchDeletePerformance,
} from '@/store/main/actions';
import { IPerformance, IPerformanceCreate, IPerformanceUpdate } from '@/interfaces';
import moment from 'moment';


moment.locale(window.navigator.language);

@Component
export default class Performances extends Vue {
  public dialogCreateUpdate: boolean = false;
  public dialogDelete: boolean = false;
  public headers = [
    // {
    //   text: 'Code',
    //   align: 'start',
    //   value: 'code',
    // },
    { text: 'Screen', value: 'screen.description' },
    { text: 'Movie', value: 'film.full_title' },
    { text: '3D', value: 'is_3d' },
    { text: 'Time', value: 'start_time' },
    { text: 'Flags', value: 'flags' },
    { text: 'Actions', value: 'actions', sortable: false, groupable: false },
  ];
  public editedIndex: number = -1;
  public editedItem = {
    code: -1,
    screen: {
      code: undefined,
      description: '',
    },
    film: {
      code: undefined,
      full_title: '',
    },
    is_3d: false,
    start_time: '',
    flags: '',
    perfdate: '',
  };
  public defaultItem = {
    code: -1,
    screen: {
      code: undefined,
      description: '',
    },
    film: {
      code: undefined,
      full_title: '',
    },
    is_3d: false,
    start_time: '',
    flags: '',
    perfdate: '',
  };
  public validForm: boolean = false;
  public selectedDate: string = moment().format('YYYY-MM-DD');
  public selectedDateMenu: boolean = false;

  get formTitle() {
    return this.editedIndex === -1 ? 'New Performance' : 'Edit Performance';
  }

  get screens() {
    return readScreens(this.$store);
  }

  get films() {
    return readFilms(this.$store);
  }

  get performances() {
    return readPerformances(this.$store);
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  public async mounted() {
    await this.initialize();
  }

  public editItem(item) {
    this.editedIndex = this.performances.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogCreateUpdate = true;
  }

  public deleteItem(item) {
    this.editedIndex = this.performances.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public async deleteItemConfirm() {
    await dispatchDeletePerformance(this.$store, this.editedItem.code);
    this.closeDelete();
  }

  public closeCreateUpdate() {
    this.dialogCreateUpdate = false;
    this.$validator.reset();
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$validator.reset();
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      if (this.editedIndex > -1) {
        const updatedPerformance: IPerformanceUpdate = {};
        updatedPerformance.film_code = this.editedItem.film.code;
        updatedPerformance.flags = this.editedItem.flags;
        updatedPerformance.is_3d = this.editedItem.is_3d ? 'Y' : 'N';
        updatedPerformance.screen_code = this.editedItem.screen.code;
        updatedPerformance.start_time = this.editedItem.start_time;
        updatedPerformance.perfdate = this.editedItem.perfdate;
        await dispatchUpdatePerformance(
          this.$store, { performanceCode: this.editedItem.code, performance: updatedPerformance },
        );
      } else {
        const createdPerformance: IPerformanceCreate = {
          screen_code: this.editedItem.screen.code,
          film_code: this.editedItem.film.code,
          is_3d: this.editedItem.is_3d ? 'Y' : 'N',
          flags: this.editedItem.flags,
          start_time: this.editedItem.start_time,
          perfdate: this.selectedDate,
        };
        await dispatchCreatePerformance(this.$store, createdPerformance);
      }
      this.closeCreateUpdate();
    }
  }

  get computedDateFormatted() {
    return this.selectedDate ? moment(this.selectedDate).format('L') : '';
  }

  @Watch('dialogCreateUpdate')
  private watchDialog(val: boolean) {
    if (!val) {
      this.closeCreateUpdate();
    }
  }

  @Watch('dialogDelete')
  private watchDialogDelete(val: boolean) {
    if (!val) {
      this.closeDelete();
    }
  }

  private async initialize() {
    if (this.siteCode) {
      await dispatchGetPerformances(this.$store, this.selectedDate);
      await dispatchGetFilms(this.$store);
      await dispatchGetScreens(this.$store);
    }
  }

  @Watch('selectedDate')
  private async watchSelectedDate(val: string) {
    await dispatchGetPerformances(this.$store, val);
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }
}
